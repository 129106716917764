import { createRouter, createWebHistory } from "vue-router";
import AuthRequired from '@/utils/AuthRequired'

const routes = [
	{
		path: '/',
		alias: '/page/app',
		component: () => import('./views/app'),
		redirect: '/page/app/travel-invoices',
		//beforeEnter: AuthRequired,
		children: [
			{
				name: 'travel-invoices',
				path: '/page/app/travel-invoices',
				component: () => import(
					'./views/app/travel-invoices'
				),
			},
			{
				name: 'recaps',
				path: '/page/app/recaps',
				component: () => import(
					'./views/app/recaps'
				),
			},
			{
				name: 'traveldocuments',
				path: '/page/app/traveldocuments',
				component: () => import(
					'./views/app/traveldocuments'
				),
			},
			{
				name: 'direct-debits',
				path: '/page/app/direct-debits',
				component: () => import(
					'./views/app/direct-debits'
				),
			},
			{
				name: 'invoicing',
				path: '/page/app/invoicing/:runId',
				component: () => import(
					'./views/app/invoicing'
				),
			},
			{
				name: 'invoicing',
				path: '/page/app/invoicing',
				component: () => import(
					'./views/app/invoicing'
				),
			},
			{
				name: 'administrator',
				path: '/page/app/administrator',
				component: () => import(
					'./views/app/administrator'
				),
			},
			{
				name: 'settings',
				path: '/page/app/settings',
				component: () => import(
					'./views/app/settings'
				),
			},
			{
				name: 'management',
				path: '/page/app/management',
				component: () => import(
					'./views/app/management'
				),
			},
			{
				name: 'avantio',
				path: '/page/app/avantio',
				component: () => import(
					'./views/app/avantio'
				),
			},
			{
				name: 'flight-ticket-guarantee',
				path: '/page/app/flight-ticket-guarantee',
				component: () => import(
					'./views/app/flight-ticket-guarantee'
				),
			},
		]
	},
	{
		name: 'User',
		path: '/page/user',
		component: () => import(
			'./views/public/user'
		),
		children: [
			{ path: 'login', component: () => import('./views/public/user/Login')  },
			{ path: 'register', component: () => import('./views/public/user/Register') },
			{ path: 'forgot-password', component: () => import('./views/public/user/ForgotPassword') },
			{ path: 'register/:token', component: () => import('./views/public/user-register') }
		],
	},
	{ path: '/page/login', redirect: '/page/user/login' },
	{ path: '/error', component: () => import(/* webpackChunkName: "error" */ './views/Error') },
	{ path: '/:pathMatch(.*)*', component: () => import(/* webpackChunkName: "error" */ './views/Error') },
]

const router = createRouter({
	linkActiveClass: 'active',
	routes,
	history: createWebHistory(),
});

export default router;
